import TinyCarousel from '../uitools/carousel';

const BetaComponents = () => {
    return(

        <TinyCarousel />

    );
};

export default BetaComponents;