import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const TinyCarousel = () => {
  return (
    <Carousel
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={3000} 
      infinite={true} 
      arrows={false} 
      showDots={false} 
    >
      <div>
        <DotLottieReact src="/animations/lights.json" loop autoplay />
      </div>
      <div>
        <DotLottieReact src="/animations/lights.json" loop autoplay />
      </div>
      <div>
        <DotLottieReact src="/animations/lights.json" loop autoplay />
      </div>
      <div>
        <DotLottieReact src="/animations/lights.json" loop autoplay />
      </div>
    </Carousel>
  );
};

export default TinyCarousel;
