import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import './App.css';
import { ShopProvider } from './sections/ShopItems';
import {Navbar, NavbarHome} from './Navbar';
import Footer from './Footer';
import HomePage from './HomePage';
import TinyFarmPage from './TinyFarmPage';
import AppPage from './AppPage';
import ShopPage from './ShopPage';
import ConfirmPage from './ConfirmPage';
import HelpPage from './HelpPage';
import NewsPage from './NewsPage';
import NewsArticlePage from './NewsArticlePage';
import LoginPage from './LoginPage';
import UserManual from './UserManual';
import MapChart from './Map'
import BetaComponents from "./beta/components";

function RenderNavbar() {
  const location = useLocation();
  if (location.pathname === '/') {
    return <NavbarHome />;
  } else if (location.pathname === '/support') {
    return <Navbar bgColor="purple_20" className="navbar_white"/>;
  } else {
    return <Navbar className="navbar_white" />;
  }
}

function ScrollTo() {
  const { pathname, hash } = useLocation();

  const scrollToElement = () => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      // Using setTimeout to ensure any dynamic content has been rendered
      setTimeout(() => {
        const element = document.getElementById(hash.replace('#', ''));
        if (element) {
          element.classList.add('open');
          setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 50);
        }
      }, 0);
    }
  };

  // One-time actions for when the entire page, including assets, has loaded
  useEffect(() => {
    const handleLoad = () => {
        scrollToElement();
    };
    window.addEventListener('load', handleLoad);
    return () => window.removeEventListener('load', handleLoad);
  }, []);

  // Listening for changes in pathname or hash to handle SPA navigation
  useEffect(() => {
    scrollToElement();
  }, [pathname, hash, scrollToElement]);

  return null;
}

function App() {

  return (
    <div className="app_wrapper">
      <Router>
        <ScrollTo />
        <RenderNavbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tinyfarm" element={<TinyFarmPage />} />
          <Route path="/app" element={<AppPage />} />
          <Route path="/shop" element={<ShopProvider><ShopPage /></ShopProvider>} />
          <Route path="/confirmation" element={<ConfirmPage />} />
          <Route path="/support" element={<HelpPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news/:url" element={<NewsArticlePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/map" element={<MapChart />} />
          <Route path="/components" element={<BetaComponents />} />
          <Route path="/user-manual" element={<UserManual />} />
        </Routes>
        <Footer />
      </Router>
    </div>  
    );
}

export default App;
