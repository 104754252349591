import React, { useState, useEffect, useRef } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { ReactComponent as ContinentsMap } from "../assets/maps/world-map-stroke-new.svg";
import axios from "axios";

const countriesURL = process.env.PUBLIC_URL + "/data/maps/topojson-countries.json";
const excludedCountries = ["RUS", "PRK", "IRN", "ISR", "SYR"]; // Excluded countries

const MapChart = () => {
  const [hoveredCountry, setHoveredCountry] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null); // Track the selected partner
  const [nonPartnerCountry, setNonPartnerCountry] = useState(null);
  const [partners, setPartners] = useState([]);
  const accordionRefs = useRef({});
  const isMobile = useRef(false);

  useEffect(() => {
    // Detect if the user is on a mobile device
    isMobile.current = /Mobi|Android/i.test(navigator.userAgent);

    // Fetch the partner data from the JSON file
    // axios.get("/partners/partners.json")
    axios.get("/partners/partners-temp.json")
      .then(response => {
        setPartners(response.data.partners);
      })
      .catch(error => {
        console.error("Error fetching partners data:", error);
      });
  }, []);

  const handleMouseEnter = (geo) => {
    setHoveredCountry(geo.properties.iso_a3);
  };

  const handleMouseLeave = () => {
    setHoveredCountry(null);
  };

  const handleClick = (geo) => {
    const countryIso = geo.properties.iso_a3;
    const countryName = geo.properties.name || geo.properties.NAME;

    const partner = partners.find((partner) =>
      partner.iso.includes(countryIso)
    );

    if (partner) {
      setSelectedPartner(partner); // Set the selected partner object
      setNonPartnerCountry(null);

      if (accordionRefs.current[partner.name]) {
        const parent = accordionRefs.current[partner.name].parentElement;
        const parentTop = parent.getBoundingClientRect().top;
        const itemTop = accordionRefs.current[partner.name].getBoundingClientRect().top;

        parent.scrollTo({
          top: parent.scrollTop + (itemTop - parentTop),
          behavior: "smooth",
        });
      }
    } else {
      setSelectedPartner(null);
      setNonPartnerCountry(countryName);

      // Scroll to the top where the non-partner message is located
      if (accordionRefs.current['non-partner']) {
        const parent = accordionRefs.current['non-partner'].parentElement;
        parent.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  };

  const handlePartnerSelect = (partner) => {
    setSelectedPartner(partner); // Set the selected partner directly
    setNonPartnerCountry(null);

    if (accordionRefs.current[partner.name]) {
      const parent = accordionRefs.current[partner.name].parentElement;
      const parentTop = parent.getBoundingClientRect().top;
      const itemTop = accordionRefs.current[partner.name].getBoundingClientRect().top;

      parent.scrollTo({
        top: parent.scrollTop + (itemTop - parentTop),
        behavior: "smooth",
      });
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "300px", marginRight: "20px" }}>
        <h3>Find Your Local Partner</h3>
        <div
          style={{
            maxHeight: "460px",
            overflowY: "auto",
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <div
            ref={(el) => (accordionRefs.current['non-partner'] = el)}
            style={{
              marginBottom: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              padding: "10px",
              backgroundColor: "#f9f9f9",
            }}
          >
            {nonPartnerCountry && (
              <p>
                We don't currently have a tiny partner in {nonPartnerCountry}. Interested in partnering with us? Please contact
                <a href="mailto:sales@tiny.science"> sales@tiny.science</a>.
              </p>
            )}
          </div>

          {/* Group partners by name and render each partner only once */}
          {partners.map((partner) => (
            <div
              key={partner.name}
              ref={(el) => (accordionRefs.current[partner.name] = el)}
              style={{
                marginBottom: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <button
                onClick={() => handlePartnerSelect(partner)}
                style={{
                  width: "100%",
                  textAlign: "left",
                  padding: "10px",
                  background: selectedPartner && selectedPartner.name === partner.name ? "#f0f0f0" : "#ffffff",
                  cursor: "pointer",
                  outline: "none",
                  border: "none",
                  fontWeight: selectedPartner && selectedPartner.name === partner.name ? "bold" : "normal",
                }}
              >
                {partner.name}
              </button>
              {selectedPartner && selectedPartner.name === partner.name && (
                <div style={{ padding: "10px", backgroundColor: "#f9f9f9" }}>
                  <img src={partner.logo} alt={`${partner.name} logo`} style={{ width: "50px", height: "auto", marginBottom: "10px" }} />
                  <p>{partner.description}</p>
                  <p>Contact details, office locations, etc.</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Interactive Map */}
      <div style={{ position: "relative", width: "858px", height: "500px" }}>
        <ComposableMap
          width={858}
          height={500}
        >
          <Geographies geography={countriesURL}>
            {({ geographies }) =>
              geographies
                .filter((geo) => !excludedCountries.includes(geo.properties.iso_a3)) // Filter out excluded countries
                .map((geo) => {
                  const isHighlighted = partners.some((p) =>
                    p.iso.includes(geo.properties.iso_a3)
                  );
                  const isSelected = selectedPartner && selectedPartner.iso.includes(geo.properties.iso_a3);
                  const isOcean = !geo.properties.iso_a3;

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={!isOcean ? () => handleMouseEnter(geo) : undefined}
                      onMouseLeave={!isOcean ? handleMouseLeave : undefined}
                      onClick={!isOcean ? () => handleClick(geo) : undefined}
                      style={{
                        default: {
                          fill: isSelected || hoveredCountry === geo.properties.iso_a3 ? "#925FF2" : isHighlighted ? "#DFD6FF" : "#fff", // Highlight selected countries in the same color as hover
                          strokeWidth: 0,
                          outline: "none",
                        },
                        hover: {
                          fill: isHighlighted ? "#925FF2" : "#ECEEED",
                          outline: "none",
                          cursor: !isOcean ? "pointer" : "default",
                        },
                        pressed: {
                          fill: "#925FF2",
                          strokeWidth: 0,
                          outline: "none",
                        },
                      }}
                    />
                  );
                })
            }
          </Geographies>

          <g style={{ pointerEvents: "none" }}>
            <ContinentsMap width="858px" height="500px" preserveAspectRatio="xMidYMid meet" />
          </g>
        </ComposableMap>
      </div>
    </div>
  );
};

export default MapChart;
